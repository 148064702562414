@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	scroll-behavior: smooth;
	display: block;
	font-family: 'Roboto';
	margin: 0;
	user-select: none;
}

::-webkit-scrollbar {
	display: none;
}
